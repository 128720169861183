<template>
  <div class="main">
    <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
      <a-tabs
        :activeKey="customActiveKey"
        :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
        @change="handleTabClick">
        <a-tab-pane key="tab1" :tab="$t('user.login.tab-login-credentials')">
          <a-alert
            v-if="isLoginError"
            type="error"
            showIcon
            style="margin-bottom: 24px;"
            :message="$t('user.login.message-invalid-credentials')" />
          <a-form-item>
            <a-input
              size="large"
              type="text"
              :placeholder="$t('user.login.username.placeholder')"
              v-decorator="[
                'username',
                {rules: [{ required: true, message: $t('user.userName.required') }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
              ]"
            >
              <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input-password
              size="large"
              :placeholder="$t('user.login.password.placeholder')"
              v-decorator="[
                'password',
                {rules: [{ required: true, message: $t('user.password.required') }], validateTrigger: 'blur'}
              ]"
            >
              <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input-password>
          </a-form-item>
          <a-button type="link" @click="initialDeliver1">忘记密码？</a-button>
        </a-tab-pane>
      </a-tabs>

      <a-form-item style="margin-top:24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
        >{{ $t('user.login.login') }}
        </a-button>
      </a-form-item>
    </a-form>

    <div class="login_logo">
      <img :src="loginLogo" alt="">
    </div>

    <two-step-captcha
      v-if="requiredTwoStepCaptcha"
      :visible="stepCaptchaVisible"
      @success="stepCaptchaSuccess"
      @cancel="stepCaptchaCancel"
    ></two-step-captcha>

    
    <a-modal title='忘记密码' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='账号' prop='username'>
          <a-input v-model='deliver1.form.username' />
        </a-form-model-item>
        <a-form-model-item label='绑定手机号' prop='mobile'>
          <a-input v-model='deliver1.form.mobile' />
          <a-button :loading="deliver1.loading" :disabled="deliver1.downtime > 0" type='primary' @click='getMsgCode'>
            {{deliver1.downtime == -1 ? "获取验证码" : deliver1.downtime == 0 ? "重新获取" : (deliver1.downtime + "S")}}
          </a-button>
        </a-form-model-item>
        <a-form-model-item label='验证码' prop='smsCode'>
          <a-input v-model='deliver1.form.smsCode' />
        </a-form-model-item>
        <a-form-model-item label='新密码' prop='password'>
          <a-input-password v-model="deliver1.form.password"></a-input-password>
        </a-form-model-item>
        <a-form-model-item label='确认密码' prop='confirmPassword'>
          <a-input-password v-model="deliver1.form.confirmPassword"></a-input-password>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import md5 from 'md5'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getSmsCaptcha, get2step, restPassword, getMsgCode } from '@/api/login'

export default {
  components: {
    TwoStepCaptcha
  },
  data () {
    return {
      loginLogo: require('@/assets/login_logo.jpg'),
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      deliver1: { 
        visible: false, 
        loading: false,
        downtime: -1,
        form: {},
        rules: {          
          username: [{ required: true, message: '请输入账号', trigger: 'change' }],
          mobile: [
            { required: true, message: '请填写手机号', trigger: 'change' },
            { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
          ],   
          smsCode: [{ required: true, message: '请输入验证码', trigger: 'change' }],
          password: [{ required: true, message: '请输入新密码', trigger: 'change' }],
          confirmPassword: [
            { required: true, message: '请输入新密码', trigger: 'change' },
            { validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入新密码'));
              } else if (value !== this.deliver1.form.password) {
                callback(new Error("两次输入的密码不一样"));
              } else {
                callback();
              }
            }, trigger: 'change' },
          ],
        },
      },
    }
  },
  created () {
    // get2step({})
    //   .then(res => {
    //     this.requiredTwoStepCaptcha = res.result.stepCode
    //   })
    //   .catch(() => {
    //     this.requiredTwoStepCaptcha = false
    //   })
    // this.requiredTwoStepCaptcha = true
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // handler
    getMsgCode() {
      if (!/^1[3456789]\d{9}$/.test(this.deliver1.form.mobile)) {
        this.$message.warning('请输入正确手机号')
        return;
      }
      this.deliver1.loading = true;
      getMsgCode({
        username: this.deliver1.form.username,
        mobile: this.deliver1.form.mobile,
        type: 1
      }).then(_result => {
        this.$message.success('操作成功')
        this.deliver1.downtime = 60;
        const timer = setInterval(() => {
          this.deliver1.downtime--;
          if (this.deliver1.downtime <= 0) {
            clearInterval(timer);
          }
        }, 1000)
        this.deliver1.loading = false;
      }).catch(() => {
        this.deliver1.loading = false;
      })
    },
    initialDeliver1(record) {
      this.deliver1.form = {};
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          this.deliver1.loading = true;
          restPassword(Object.assign(this.deliver1.form, {password: md5(this.deliver1.form.password)})).then(_result => {
            this.$message.success('操作成功')
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleTabClick (key) {
      this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey,
        Login
      } = this

      state.loginBtn = true

      const validateFieldsKey = customActiveKey === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values }
          delete loginParams.username
          loginParams[!state.loginType ? 'email' : 'username'] = values.username
          loginParams.password = md5(values.password)
          Login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          getSmsCaptcha({ mobile: values.mobile }).then(res => {
            setTimeout(hide, 2500)
            this.$notification['success']({
              message: '提示',
              description: '验证码获取成功，您的验证码为：' + res.result.captcha,
              duration: 8
            })
          }).catch(err => {
            setTimeout(hide, 1)
            clearInterval(interval)
            state.time = 60
            state.smsSendBtn = false
            this.requestFailed(err)
          })
        }
      })
    },
    stepCaptchaSuccess () {
      this.loginSuccess()
    },
    stepCaptchaCancel () {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess (res) {
      if (res.username !== "admin" && (!res.menus || res.menus.length == 0)) {
        this.$notification['error']({
          message: '错误',
          description: '无权限登录，请联系管理员',
          duration: 4
        })
        this.Logout();
      } else {
        this.$router.push({ path: '/' })
        // 延迟 1 秒显示欢迎信息
        setTimeout(() => {
          this.$notification.success({
            message: '欢迎',
            description: `${timeFix()}，欢迎回来`
          })
        }, 1000)
        this.isLoginError = false
      }
    },
    requestFailed (err) {
      this.isLoginError = true
      this.$notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    }
  }
}
</script>

<style lang='less' scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
.login_logo {
  img {
    width: 100%;
  }
}
</style>
